<template>
  <div>
    <GeneralFilter
      entity="transferencia"
      :filterEndpoint="$API.inventoryTransfer.filterTransfers"
      :search="search"
      @filtered="filterHandler"
    >
      <DxDataGrid>
        <DxExport :enabled="true" />
        <DxScrolling row-rendering-mode="virtual" />
        <!--DxPaging :page-size="10" /-->
        <DxColumn
          :width="100"
          cell-template="show-template"
          caption=""
          v-if="$store.getters['rolesUser/getCustomRole'](113)"
        ></DxColumn>
        <DxColumn data-field="DateReq" caption="Fecha de solicitud"></DxColumn>
        <DxColumn
          data-field="DateTransfer"
          caption="Fecha de Aprobación"
        ></DxColumn>
        <DxColumn data-field="DestinyWarehouse" caption="Destino"></DxColumn>
        <DxColumn data-field="ReqName" caption="Usuario Solicita"></DxColumn>
        <DxColumn data-field="userName" caption="Usuario Aprobación"></DxColumn>
        <template :v-model="show - template">
          <a href="#" class="mx-1">
            <v-icon color="primary">mdi-eye</v-icon>
          </a>
        </template>
      </DxDataGrid>
    </GeneralFilter>

    <div>
      <v-dialog v-model="showModal" max-width="1024">
        <v-card>
          <v-card-title>
            <v-row class="bg-dark text-h6 pa-2 rounded-lg">
              <v-col cols="12" md="2" align="center">
                <span class="font-weight-bold">ID Convesion: </span>
              </v-col>
              <v-col cols="12" md="2" align="center">
                <span class="font-weight-bold">ID Solicitud:</span>
              </v-col>
              <v-col cols="12" md="3" align="center">
                <span class="font-weight-bold">Almacen Origen:</span>
                <div>Contenido</div>
              </v-col>
              <v-col cols="12" md="2" align="center">
                <span class="font-weight-bold"> Destino:</span>
                <div>Contenido</div>
              </v-col>
              <v-col cols="12" md="3" align="center">
                <span class="font-weight-bold">Usuario Solicita:</span>
                <div>usuario</div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-col cols="12">
              <v-btn
                block
                dark
                large
                color="comment"
                class="rounded-lg"
                @click.prevent="showNotes = !showNotes"
              >
                <v-icon>mdi-comment</v-icon>
                <span class="text-h6">Comentarios</span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <span class="font-weight-bold">Fecha Solicitud:</span>
            </v-col>
            <v-col cols="12" md="6">
              <span class="font-weight-bold">Fecha Aprobación:</span>
            </v-col>

            <GeneralFilter
              entity="transferencia"
              :filterEndpoint="filterTransferProduct"
              :search="search"
              @filtered="filterHandler"
            >
              <DxDataGrid
                :show-row-line="ShowRowLines"
                :show-border="showBorder"
                width="100%"
              >
                <DxExport :enabled="true" />
                <DxPaging :page-size="10" />
                <DxColumn caption="ID Producto"></DxColumn>
                <DxColumn caption="Nombre"></DxColumn>
                <DxColumn caption="Cantidad Solicitada"></DxColumn>
                <DxColumn caption="Nueva existencia (Origen)"></DxColumn>
                <DxColumn caption="Nueva existencia (Destino)"></DxColumn>
              </DxDataGrid>
            </GeneralFilter>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog max-width="1024" v-model="showNotes">
        <v-card>
          <v-card-title>
            <v-spacer />
            <v-btn small dark color="error" @click.prevent="showNotes = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <quill-editor
              :content="form.notes"
              :options="quillOptions"
              @change="onEditorChange($event)"
            ></quill-editor>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter.vue";
import { formatDate } from "../../../helpers/formatDate";
import {
  DxDataGrid,
  DxExport,
  DxColumn,
  DxScrolling,
  DxPaging,
} from "devextreme-vue/data-grid";

export default {
  name: "RequestConversion",
  components: {
    GeneralFilter,
    DxDataGrid,
    DxColumn,
    DxExport,
    DxScrolling,
    DxPaging,
  },
  data() {
    return {
      showModal: false,
      ShowRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      showNotes: false,
      form: {},
      search: {
        filter: null,
      },
    };
  },
  methods: {
    filterHandler(response) {
      if (response.length > 0) {
        this.transfer = response.map((transfer) => ({
          ...transfer,
          TransferDate: formatDate(transfer.TransferDate),
        }));
      } else {
        this.transfer = response;
      }
    },

    filterTransferProduct({ filter, products }) {
      let filteredProducts = products.filter(
        (product) =>
          product.ID_Item.toString().includes(filter) ||
          product.ItemName.includes(filter) ||
          product.CantidadSolicitada.toString().includes(filter)
      );

      return new Promise((resolve) => {
        resolve(filteredProducts);
      });
    },

    onEditorChange(e) {
      this.form.comments = e.html;
    },
  },
  props: {
    transferData: {
      type: Object,
    },
    isTransferApplication: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
