<template>
  <workspace-panel :win="win" @reload="reload">
    <template #title>BUSCAR TRASPASOS</template>
    <template>
      <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
        <v-tabs-slider color="red"></v-tabs-slider>
        <v-tab
          href="#tab-1"
          v-if="$store.getters['rolesUser/getCustomRole'](174)"
        >
          HISTORIAL CONVERSION
          <v-icon>mdi-archive-plus</v-icon>
        </v-tab>
        <!--v-tab
          href="#tab-2"
          v-if="$store.getters['rolesUser/getCustomRole'](174)"
        >
          SOLICITUDES CONVERSION
          <v-icon>mdi-archive-edit</v-icon>
        </v-tab-->
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-1">
          <v-card flat class="pa-1 rounded-lg">
            <v-card-text class="white">
              <HistoryConversion :win="win" :key="key" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-2">
          <v-card flat class="pa-1 rounded-lg">
            <v-card-text class="white">
              <RequestConversion :win="win" :key="key" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </workspace-panel>
</template>

<script>
import HistoryConversion from "./HistoryConversion.vue";
import RequestConversion from "./RequestConversion.vue";

export default {
  name: "SearchProductsComposite",
  components: {
    HistoryConversion,
    RequestConversion,
  },
  props: ["win"],
  data() {
    return {
      tab: null,
      key: 0,
    };
  },
};
</script>
